jQuery(document).ready(function ($) {

  // ギャラリーの設定
  var $gallery = $('.js-index-gallery');
  $gallery.masonry({
    gutter: 20,
    columnWidth: 235
  });
  var scrollpos = 0;
  scrollpos = $(window).scrollTop() + $(window).height();
  if (scrollpos > $gallery.offset().top) {
    $gallery.masonry('layout');
  }
  $(window).scroll(function () {
    scrollpos = $(window).scrollTop() + $(window).height();
    if (scrollpos > $gallery.offset().top) {
      $gallery.masonry('layout');
    }

    $('.js-fadein').each(function () { // スクロールでフェードイン
      var objPos = $(this).offset().top;
      if (scrollpos > objPos) {
        $(this).css({
          opacity: 1,
          transform: 'translate(0, 0)',
          transition: 'all 1000ms'
        });
      }
    });
  });

  // スクロールでフェードイン要素を設定する
  if ($('.js-fadein')[0]) {
    $('.js-fadein').each(function () {
      var objPos = $(this).offset().top;
      if (scrollpos < objPos) {
        $(this).css({
          opacity: 0,
          transform: 'translate(0, 100px)'
        });
      }
    });
  }
});
